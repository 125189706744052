import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const BASE_URL: string = 'https://api.flakon.uz/api/'
const TIMEOUT: 20000 = 20000
const USER_TOKEN_COOKIE_NAME: string = '$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$'

const myAxios: AxiosInstance = axios?.create({ baseURL: BASE_URL, timeout: TIMEOUT, headers: { 'Content-Type': 'application/json' } })

myAxios?.interceptors?.request?.use(
  (config: InternalAxiosRequestConfig) => {
    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'
    const params: URLSearchParams = new URLSearchParams(window?.location?.search)

    const USER_TOKEN: string =
      isFromTelegramBot && (config?.url === '/users/userme' || config?.url === '/users/register') && params?.get('telegram_bot')
        ? params?.get('telegram_bot') || ''
        : Cookies?.get(USER_TOKEN_COOKIE_NAME) || ''

    if (USER_TOKEN) config.headers['Authorization'] = USER_TOKEN
    return config
  },
  (error) => Promise?.reject(error),
)

myAxios?.interceptors?.response?.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise?.reject(error),
)

export default myAxios