import { Suspense, useEffect, useState } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import { io } from 'socket.io-client'
import myAxios from 'service/axios'
import Cookies from 'js-cookie'

const socket = io('https://api.flakon.uz')

// Redux store
import { useDispatch } from 'react-redux'
import { changeLoading, changeSettings } from 'store/store'

// Routes list
import generateRoutes from './routesLinks'

// Custom hooks
import { useTypedSelector } from 'hooks'

// Components
import { Loader } from 'components'

// Helpers
import { checkTokenValidity } from 'helpers'

// Locale languages
import i18n from 'i18next'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing Locale translated texts
import TranslationEN from 'Locale/En'
import TranslationRU from 'Locale/Ru'
import TranslationUZ from 'Locale/Uz'

i18n?.use(initReactI18next)?.init({
  resources: {
    en: { translation: TranslationEN },
    ru: { translation: TranslationRU },
    uz: { translation: TranslationUZ },
  },
  lng: localStorage?.getItem('$flakon$.$uz$language$') || 'ru',
  fallbackLng: localStorage?.getItem('$flakon$.$uz$language$') || 'ru',
  interpolation: { escapeValue: false },
})

// Types
import { IRootState } from 'types'

// Constants
import { localeSettings } from '../constants'

export default function Router(): JSX.Element {
  const params: URLSearchParams = new URLSearchParams(window?.location?.search)
  const pathname = useLocation()?.pathname
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(true)

  const {
    settings,
    isLoading,
    user: { isAuth, isAccountingAllowed, isOrderingAllowed, role },
  } = useTypedSelector((s: IRootState) => s.store)

  const routes = useRoutes(generateRoutes(isAuth, isAccountingAllowed, isOrderingAllowed, role))

  const changeLoader = (param: boolean) => {
    setLoading(param)
  }

  async function checking() {
    if (!Cookies?.get('$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$') && !params?.get('telegram_bot')) {
      dispatch(changeLoading(false))
      setLoading(false)
      return
    }

    await checkTokenValidity(dispatch, changeLoader)

    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined' && pathname?.includes('/register')
    if (!isFromTelegramBot) {
      params?.delete('telegram_bot')
      window?.history?.pushState({}, '', `?${params?.toString()}`)
    }
  }

  async function getSettings() {
    try {
      const response = await myAxios('/about')
      dispatch(changeSettings(response?.data))
    } catch {
      dispatch(changeSettings(localeSettings))
    }
  }

  useEffect(() => {
    const currLang: boolean = params?.get('lang')?.toLowerCase() === 'en' || params?.get('lang')?.toLowerCase() === 'ru' || params?.get('lang')?.toLowerCase() === 'uz'
    if (!localStorage?.getItem('$flakon$.$uz$language$') && !currLang) localStorage?.setItem('$flakon$.$uz$language$', 'ru')

    if (currLang) {
      i18next?.changeLanguage(params?.get('lang')?.toLowerCase() || 'ru')
      localStorage?.setItem('$flakon$.$uz$language$', params?.get('lang')?.toLowerCase() || 'ru')
      document.documentElement.lang = params?.get('lang')?.toLowerCase() || 'ru'
    }

    if (params?.get('lang')) {
      params?.delete('lang')
      window?.history?.pushState({}, '', `?${params?.toString()}`)
    }

    if ((settings && settings?.id === 'locale-settings') || !settings || !settings.id) getSettings()
  }, [])

  // Checking user token validity
  useEffect(() => {
    if (navigator?.onLine) checking()

    socket?.emit('onlineUsers')
    return () => {
      socket.off('onlineUsers')
    }
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {!loading && routes}
      {isLoading && <Loader full={!isAuth} />}
    </Suspense>
  )
}
